import LocalizedStrings from 'react-localization';

import { emojis, unicode } from './';

const strings = new LocalizedStrings({
  en: {
    alerts: {
      noOrders: `Sorry, you don't have any orders yet`,
      noVendors: `No vendors found.`,
    },
    app: {
      name: 'Goodfynd',
      nameLegal: 'Goodfynd, Inc.',
    },
    errors: {
      productLoading: 'Error loading product. Please try again.',
      productUnknown: 'Unknown product. Please try again.',
      tryAgain: 'An error occurred. Please try again.',
      unknown: 'An unknown error has occurred.',
    },
    labels: {
      accept: 'Accept',
      acceptingOnlineOrders: 'Online ordering enabled',
      add: 'Add',
      addCreditCard: 'Add Credit Card',
      addEmployee: 'Import Staff Member',
      addEvent: 'Add Event',
      addEvents: 'Add Event(s)',
      addLicense: 'Add license or permit',
      addOrganization: 'Add Organization',
      addToSchedule: 'Add to schedule',
      addVendor: 'Add Vendor',
      addYourTruck: 'Add Your Truck',
      allTrucks: 'All Trucks',
      alreadyMember: 'Already have an account',
      applied: 'Applied!',
      apply: 'Apply',
      authorized: `Authorized! ${emojis.happy}`,
      awaitingConfirmation: 'Awaiting Confirmation',
      bagEmpty: `Your Bag's Empty`,
      beforePickupExpires: 'before pickup time slot is removed',
      bookATruck: 'Book a Truck',
      booked: 'Booked',
      bookTruckToday: 'Book a Food Truck Today',
      bookVendor: 'Book Vendor',
      cancel: 'Cancel',
      canceled: 'Canceled',
      cancelEvent: 'Cancel event',
      cancelOrder: 'Cancel order',
      cash: 'Cash',
      changePickupTime: 'Change Pickup Time',
      choosePickupTime: 'Choose Pickup Time',
      chooseProfile: 'Choose a Profile',
      comingSoon: 'Coming Soon!',
      complete: 'Complete',
      completed: 'Completed',
      confirm: 'Confirm',
      confirmed: 'Confirmed!',
      continue: 'Continue',
      continueToPayment: 'Continue to Payment',
      continueWithEmail: 'or continue with email',
      copied: 'Copied!',
      copiedToClipboard: 'Copied to clipboard!',
      createEvent: 'Create an Event',
      createInvoice: 'Create invoice',
      currentPickupLocation: 'Current Pickup Location',
      customTip: 'Custom Tip',
      darkMode: 'Night Mode',
      date: 'Date',
      description: 'Description',
      dindinTime: `Din' Din' time`,
      dinnerTime: 'Dinner time',
      discount: 'Discount',
      earnMore: 'Earn More Today',
      editEmployee: 'Edit Employee',
      editEvents: 'Edit Event(s)',
      editOrganization: 'Edit Organization',
      editVendor: 'Edit Vendor',
      editBusinessInfo: 'Edit business info',
      email: 'Email Address',
      enterPromoCode: 'Enter Promo Code',
      eSign: 'E-Sign\u00A0Consent',
      events: 'Events',
      eventDetails: 'Event details',
      favorite: 'Favorite',
      favorites: 'Favorites',
      feelingHungry: 'Feeling hungry',
      findFood: 'Find Food',
      findMoreFood: 'Find More Food',
      findTrucks: 'Find Trucks',
      firstName: 'First Name',
      getDirections: 'Get Directions',
      goodfyndEvent: 'Goodfynd Event',
      homeGeoPlaceholder: 'Set your location',
      homeSearchPlaceholder: 'Search trucks, food items, events...',
      hungry: 'Hungry',
      imageHelp: 'Preferred aspect ratio is 16:9. Must be .jpg, .png or .heic.',
      inProgress: 'In-Progress',
      issueCredit: 'Issue Credit',
      interested: "I'm Interested",
      itemNotAvailable: 'This item is currently not available for purchase.',
      items: 'Items',
      lastName: 'Last Name',
      leaveNotes: 'Want to leave any notes for the chef?',
      letsDoThis: 'Let’s Do This',
      letsEat: `Let's eat`,
      loadMore: 'Load More',
      login: 'Login',
      loginRequiredEvents: 'is required to view events',
      loginWithFacebook: 'Login with Facebook',
      loginWithGoogle: 'Login with Google',
      logout: 'Logout',
      markPaid: 'Mark Paid',
      memo: 'Memo',
      message: 'Message',
      moreEventsAtLocation: 'More Events at this Location',
      myBusinesses: 'My Businesses',
      myEvents: 'My Events',
      nevermind: 'Nevermind',
      newToGoodfynd: 'New to Goodfynd',
      nextEvent: 'Next Event',
      newPassword: 'Enter your new password below',
      noEvents: 'No events upcoming',
      noLongerAvailable: 'No longer available',
      noPickupTimes: `Sorry, there are no more pickup times remaining ${emojis.sad}.`,
      noProfiles: 'No public profile',
      notForSale: 'Not for Sale',
      notify: 'Notify',
      notProvided: 'Not Provided',
      open: 'Open',
      openToday: 'Open Today',
      openTomorrow: 'Open Tomorrow',
      optional: 'Optional',
      or: 'or',
      orderHistory: 'Order History',
      orderNow: 'Order Now',
      orders: 'Orders',
      notAcceptingOnlineOrders: 'Online ordering disabled',
      password: 'Password',
      passwordMinLength:
        'A secure password must be at least 8 characters long.',
      paymentAccepted: 'Payment Accepted',
      paymentAgreement:
        'I agree to pay the above total amount according to my card issuer agreement.',
      payOut: 'Pay out',
      payWithQr: 'Pay with QR Code',
      personalInfo: 'Personal Info',
      phone: 'Phone',
      pickupTimeExpired: 'Pickup time slot has been removed',
      placeOrder: 'Place Order',
      preferences: 'Preferences',
      privacyPolicy: 'Privacy\u00A0Policy',
      private: 'Private',
      proceedToCheckout: 'Proceed to Checkout',
      products: 'Products',
      profileUpdated: 'Profile Updated',
      promoApplied: 'applied!',
      promote: 'Promote',
      reason: 'Reason',
      refund: 'Refund',
      registerVendor: 'Register Vendor',
      reject: 'Reject',
      rejected: 'Rejected',
      remind: 'Remind',
      remove: 'Remove',
      removeInterest: 'Remove Interest',
      removeItem: 'Remove Item',
      required: 'Required',
      requestPasswordReset: 'Request Password Reset',
      resendCode: 'Resend Code',
      reset: 'Reset',
      resultsNear: 'Showing results near',
      retry: 'Retry',
      save: 'Save',
      selectPaymentMethod: 'Please select valid payment method',
      send: 'Send',
      sendEmail: 'Send Email',
      sendMessage: 'Send Message',
      settings: 'Settings',
      share: 'Share',
      showingMenu: 'Showing menu for',
      signIn: 'Sign In',
      signMeUp: 'Sign Me Up',
      signUp: 'Sign Up',
      signUpForDates: `Sign Up for Dates`,
      signUpSuccessMessage:
        'Congratulations, you’ve added your truck on Goodfynd!\nWe’ll reach out to finish your account setup.',
      signUpSuccessMessageConsumer:
        'Congratulations, you’ve added your account on Goodfynd!\nWe’ll reach out to finish your account setup.',
      signUpWithFacebook: 'Signup with Facebook',
      signUpWithGoogle: 'Signup with Google',
      specialRequests: 'Special Requests',
      start: 'Start',
      startEarning: 'Start Earning More Now',
      startUsing: 'Start Using Goodfynd',
      submit: 'Submit',
      subtotal: 'Subtotal',
      taxesAndFees: 'Taxes & Fees',
      terms: 'Terms',
      thanks: 'Thanks You!',
      thisEventTrucks: `This Event's Trucks`,
      tip: 'Tip',
      today: 'Today',
      tomorrow: 'Tomorrow',
      total: 'Total',
      trucks: 'Trucks',
      tryAgain: 'Try Again',
      unFavorite: 'Unfavorite',
      update: 'Update',
      updateLocation: 'Update location',
      updatePickupTime: 'Update Pickup Time',
      updateSchedule: 'Update schedule',
      username: 'Username',
      verify: 'Verify',
      vendors: 'Vendors',
      vendorsOpen: 'Vendors Open',
      viewAllEvents: 'View All Events',
      viewFullSchedule: 'View Full Schedule',
      viewMoreEvents: 'View More Events',
      viewReceipt: 'View Receipt',
      void: 'Void',
      waitListed: 'Waitlisted',
      weEmailConfirmation: `We'll email you a confirmation and receipt`,
      welcomeBack: 'Welcome back',
      yes: 'Yes',
      yesterday: 'Yesterday',
    },
    messages: {
      accountCreationSuccess: `Please check your email and verify your account before logging in.
          If you do not receive the verification email, please check your spam folder.`,
      addEvent:
        'Want to bring unique food experiences to your events? Submit the information below to create your free personal food truck sourcing page!',
      addOrganization:
        'Want to help customers easily locate your business and order your products? Submit the information below to create your organization!',
      addVendor:
        'Want to help customers easily locate your business and order your products? Submit the information below to create your free personal page!',
      allInOne:
        'Goodfynd’s simple to use all in one system will equip you to build a consistent brand and foodie experience. Are you ready to watch your sales skyrocket?',
      goFindFood: 'Go find yourself something good to eat!',
      growYourBusiness:
        'Grow your business with Goodfynd, the platform for Food Trucks',
      license:
        'Description of the license type, which could wrap or could by pretty concise. Could even be multiple sentences.',
      passionIsFood:
        'Your passion is food. Keep doing what you do best, and we’ll send the fans, foodies, and events your way! Save time and money, all while growing your business.',
      pdfUpload: 'Click below or drag and drop your file. Must be .pdf format',
      resetPwSuccess:
        'Congratulations, you have successfully reset your password',
      simplifyGrowth:
        'Discover how to simplify your growth and join the 500+ truck owners who have already accelerated their revenue stream.',
      takeGuessworkOut:
        'You have enough on your plate. Take the guesswork out of finding the best catering for your next event.',
      verifyFailed: 'Sorry, verification failed. Please try again.',
      vendorLeadCaptured: `We saved your information and a sales representative will be in touch soon. Use the link below to continue setting up your business profile.`,
      verifyPhone:
        'We sent a special code to your mobile number. Please enter the code below to ensure that we can send you order updates.',
      verifyEmailSuccess:
        'Congratulations, your account is now verified! You may now login and order some dank food.',
      whichProfile: 'Which profile do you want to manage?',
      whichTruck: 'Which truck do you want to manage?',
    },
    misc: {
      and: 'and',
    },
    orders: {
      pickupTime: 'Estimated Pickup Time: :time',
      placed: `Placed :day at :time${unicode.centerDotPadded}:total`,
    },
    titles: {
      accessMoreCustomers: 'Access More Customers',
      addEvent: 'Add Event',
      addNewCard: 'Add New Card',
      addItem: 'Add Item',
      addOrganization: 'Add Organization',
      addTip: 'Add a tip',
      addVendor: 'Add Vendor',
      bookATruck: 'Book a Food Truck for Your Event',
      businessInfo: 'Business info',
      cancelOrder: 'Cancel order',
      cancelEvent: 'Cancel event',
      changePickupOptions: 'Change Pickup Time and Location',
      checkout: 'Checkout: Confirm Your Info',
      checkoutConfirmation: 'Checkout: Confirmation',
      checkoutReview: 'Checkout: Review',
      checkoutThanks: 'Checkout: Thanks',
      choosePaymentMethod: 'Choose Payment Method',
      choosePickupOptions: 'First, Choose Pickup Time and Location',
      choosePickupTime: 'Choose Pickup Time',
      confirmationEmailSent: `We've sent a confirmation email to`,
      confirmInfo: 'Confirm Your Info',
      confirmVendor: 'Confirm truck',
      createInvoice: 'Create Invoice',
      createQuote: 'Create Quote',
      editInvoice: 'Edit Invoice',
      editQuote: 'Edit Quote',
      customMessage: 'Custom message',
      dashboard: 'Dashboard',
      editAppointment: 'Edit appointment',
      editOrganization: 'Edit organization',
      editVendor: 'Edit vendor',
      editItem: 'Edit item',
      endToEnd: 'The end to end solution for mobile food businesses',
      eventDetails: 'Event Details',
      eventFilter: 'Sort & Filter',
      events: 'Events',
      eventsNearby: 'Events Nearby',
      faq: 'Frequently asked questions',
      favoriteTrucks: 'Your Favorite Trucks',
      favoriteVendors: 'Your Favorite Vendors',
      featuredItem: 'Featured Item',
      featureUnavailable: 'Feature Unavailable',
      flyersMarketing: 'Marketing Flyers',
      folksLoveGoodfynd: 'Why folks love Goodfynd events',
      foodTrucksCatering: 'Food trucks, duh!',
      foodTruckSchedule: 'Food Truck Schedule',
      forFoodies: 'For Foodies',
      forTruckOwners: 'For Truck Owners',
      fromTheBlog: 'From the Blog',
      gatherSafely: 'Gathering safely',
      getListed: 'Get Your Truck Listed',
      helpCenter: 'Help Center',
      homeTitle: 'Fuel Your Food Truck Success',
      howSimple: 'How simple is it?',
      hungry: 'Hungry?',
      hungrySubTitle: 'Find and order from food trucks near you!',
      instantPayout: 'Instant payout',
      invoiceDetails: 'Invoice details',
      invoices: 'Invoices',
      joinGoodfyndCommunity: 'Join The Goodfynd Community',
      joinTheTeam: 'Join the Dream Team',
      logout: 'Logout',
      maxProfit: 'Maximize profitability & time with Goodfynd',
      noFussCatering: 'No Fuss Catering',
      noUpcomingEvents: 'No Upcoming Events',
      openPositions: 'Open Positions',
      orderDetails: 'Order details',
      orderingSettings: 'Ordering settings',
      orderingSettingsWizard: 'Ordering Settings Wizard',
      orderNextMeal: 'Order Your Next Meal',
      orderReceived: 'Your order has been received!',
      orders: 'Orders',
      organization: 'Organization',
      ourBenefits: 'Our Benefits',
      ourValues: 'Our Values',
      overview: 'Overview',
      pastAppointment: 'Past appointment',
      manualSalesReport: 'Manual sales report',
      payment: 'Payment',
      productDetails: 'Product Details',
      products: 'Products',
      promoCodes: 'Promo codes',
      refundInvoice: 'Refund invoice',
      refundOrder: 'Refund order',
      reports: 'Reports',
      resetPassword: 'Reset Password',
      savedCards: 'Saved Cards',
      schedule: 'Schedule',
      settings: 'Settings',
      signIn: 'Welcome Back',
      signUp: 'Create an Account',
      simplifyCatering: 'Simplify catering with one click',
      siteMaintenance: 'Site Under Maintenance',
      startServing: 'Start Serving More Customers',
      support: 'Support',
      switchProfile: 'Switch Profile',
      switchTruck: 'Switch Truck',
      takeControl: 'Take control of your business',
      transferSchedule: 'Transfer this schedule to another truck',
      trucksNearby: 'Trucks Nearby',
      turnOnOrdering: 'Turn on ordering',
      updateSchedule: 'Update schedule',
      vendorDetails: 'Vendor Details',
      verifyPhone: 'Verify Mobile Number',
      voidInvoice: 'Void invoice',
      weeklyFoodFight: 'Weekly Food Fight',
      welcome: 'Welcome',
      welcomeBack: 'Welcome back!',
      whatFolksSay: 'What Folks Say About Us',
      whatTrucksSay: 'What other trucks say about us',
      youBeenSelected: `You’ve been selected for the following dates. Are you in?`,
      yourBag: 'Your Bag',
    },
    validations: {
      acceptTerms: 'You must accept terms to continue.',
      addressRequired: 'An address is required',
      companyRequired: 'A company name is required',
      emailRequired: 'An email address is required.',
      emailInvalid: 'Please provide a valid email',
      endTimeRequired: 'A end time is required',
      firstNameRequired: 'First name is required',
      invalidEmailFormat: `A valid email can only contain latin letters, numbers, '@' and '.'.`,
      lastNameRequired: 'Last name required',
      mobileInvalid: 'Please provide a valid mobile phone number.',
      passwordLength: 'Password must be at least 8 characters long.',
      passwordRequired: 'A password is required.',
      phoneInvalid: 'Please provide a valid phone number.',
      postalCodeRequired: 'A postal code is required',
      usernameRequired: 'An email or username is required.',
      validUrl: 'Url is not valid',
      zipInvalid: 'Please provide a valid postal code.',
      faxInvalid: 'Please provide a valid fax.',
      regex: {
        nonSpecial: /[^a-zA-Z0-9]/g,
        phone: new RegExp(
          /([+]?\d{1,2}[\s]?)?(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}/
        ),
        url: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
        fax: new RegExp(
          /([+]?\d{1,2}[\s]?)?(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}/
        ),
      },
      nameRequired: 'A name is required',
      imageRequired: 'An image is required',
      descriptionRequired: 'A description is required',
      uniqe: 'Values must be uniqe',
      duplicates: 'Duplicate values not allowed',
      startTimeRequired: 'A start time is required',
      numberOfOrdersRequired: 'Number of orders is required',
      totalOrderAmountRequired: 'Order amount is required',
      businessName: 'The BusinessName field is required.',
    },
  },
});

export default strings;
