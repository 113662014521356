import { useMemo } from 'react';
import type { Customer } from 'types/customers';
import { stringUtil } from 'utils/index';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';

export const useCustomerApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async get(customerId: string): Promise<Customer> {
        try {
          const data: Customer = await api.get(
            stringUtil.replaceAll(endpoints.customers.base, {
              ':id': customerId,
            })
          );
          return data;
        } catch (error) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
