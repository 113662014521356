import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import type { ScheduleSearchOptions } from 'types/schedule';
import type { VendorCountType } from 'types/vendors';

import config from './';

export const organizationKeys = {
  all: [config.queryKeys.organization] as const,
  agreements: (organizationId = '') =>
    [...organizationKeys.details(organizationId), 'agreements'] as const,
  businessInfo: (organizationId = '') =>
    [...organizationKeys.details(organizationId), 'businessInfo'] as const,
  details: (organizationId = '') =>
    [...organizationKeys.all, organizationId] as const,
  contactDetails: (organizationId?: string) =>
    [...organizationKeys.details(organizationId), 'contact-details'] as const,

  profile: (organizationId: string) =>
    [...organizationKeys.details(organizationId), 'profile'] as const,
  structure: (organizationId?: string) =>
    [...organizationKeys.details(organizationId), 'structure'] as const,
  vendor: (vendorId: string) =>
    [...organizationKeys.all, 'detail', 'vendor', vendorId] as const,
  territories: (organizationId?: string) =>
    [...organizationKeys.details(organizationId), 'territories'] as const,
  personnel: (organizationId?: string, includeHidden?: boolean) =>
    [
      ...organizationKeys.structure(organizationId),
      'personnel',
      includeHidden,
    ] as const,
  stripeStatus: (organizationId: string) =>
    [...organizationKeys.details(organizationId), 'stripe-status'] as const,
  payouts: (organizationId: string) =>
    [...organizationKeys.details(organizationId), 'payouts'] as const,
};

export const stripekeys = {
  all: ['stripe'] as const,
  products: () => [...stripekeys.all, 'products'] as const,
  organizationProduct: (organizationId: string) =>
    [...stripekeys.all, 'products', 'organization', organizationId] as const,
};

export const vendorKeys = {
  all: [config.queryKeys.vendor] as const,
  details: (vendorId: string) => [...vendorKeys.all, vendorId] as const,
  profile: (vendorId: string) =>
    [...vendorKeys.details(vendorId), 'profile'] as const,
  summary: (vendorId = '') =>
    [...vendorKeys.profile(vendorId), 'summary'] as const,
  location: (vendorId: string) =>
    [...vendorKeys.profile(vendorId), 'location'] as const,
  count: (vendorId: string, type: VendorCountType) => [
    ...vendorKeys.all,
    type,
    vendorId,
  ],
  agreements: (vendorId = '') =>
    [...vendorKeys.details(vendorId), 'agreements'] as const,
  businessInfo: (vendorId = '') =>
    [...vendorKeys.details(vendorId), 'businessInfo'] as const,
  map: (vendorId = '') => [...vendorKeys.profile(vendorId), 'map'] as const,
  payouts: (organizationId: string) =>
    [...vendorKeys.details(organizationId), 'payouts'] as const,
};

export const calendarKeys = {
  all: ['calendar'] as const,
  events: (id = '') => [...calendarKeys.all, id] as const,
};
// updated query key pattern
export const queryKeys = createQueryKeyStore({
  schedule: {
    search: (request: ScheduleSearchOptions) => [omitBy(request, isEmpty)],
    walkup: (id?: string) => ['walkup', id],
  },
});

export const tokenKeys = {
  all: ['token'] as const,
  customerPortal: (organizationId: string, vendorId: string) =>
    [...tokenKeys.all, 'customer-portal', organizationId, vendorId] as const,
};
