import { useMemo } from 'react';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import type { CustomerPortalTokenRequest } from '../../types/tokens';

export const useCustomerPortalApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async token(organizationId: string, vendorId: string): Promise<string> {
        try {
          const request: CustomerPortalTokenRequest = {
            organizationId,
            vendorId,
          };
          return await api.post(endpoints.tokens.customerPortalToken, request);
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
