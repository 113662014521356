import { useMemo } from 'react';
import { useOrganization } from '@context/OrganizationContext';

import config from '../config';
import { useApp } from '../context/AppContext';

export default function useOrganizationAccess() {
  const { user, userIsFetched } = useApp();
  const { organization, isFetched } = useOrganization();

  return useMemo(() => {
    return {
      isLoaded: isFetched && userIsFetched,
      hasAccess:
        (isFetched &&
          organization.subscriptions?.includes(
            config.features.organizations
          )) ||
        (userIsFetched && user.isRestrictedAdmin),
    };
  }, [user, userIsFetched, organization.subscriptions, isFetched]);
}
